import React, { createContext } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export const SnackbarContext = createContext({});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackBarComp({ errorPopup, setErrorPopup }) {
    const handlePopupClose = () => {
        setErrorPopup(prev => ({ ...prev, status: false }));
    }
    // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    return (
        <Snackbar open={errorPopup.status} autoHideDuration={6000} onClose={handlePopupClose}>
            <Alert onClose={handlePopupClose} severity={errorPopup.severity}>
                {errorPopup.msg}
            </Alert>
        </Snackbar>
    )
}

export default SnackBarComp
