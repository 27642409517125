import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Logo from "./images/calculense_logo.png";
import { useNavigate } from 'react-router-dom';

const Container = tw.div`relative bg-gray-200  -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:pt-20 lg:pb-10 flex flex-wrap justify-between gap-x-6 gap-y-6 md:gap-x-0 md:gap-y-0 px-8 xl:px-0`;
const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;
const ColumnHeading = tw.h5`font-bold text-base`;
const LinkList = tw.ul`mt-4 text-sm md:text-base font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 text-gray-700 border-transparent hocus:text-gray-900 hocus:border-indigo-500 pb-1 transition duration-300`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
const CompanyDescription = tw.p`mt-4 max-w-md font-medium text-base mx-auto md:mx-0 md:mr-4 md:pr-2 tracking-wide`;
const SocialLinksContainer = tw.div`mt-4 flex justify-center md:justify-start items-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full text-gray-600 bg-gray-200 hover:text-indigo-600 transform hover:scale-125 transition duration-300 mr-1`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const CopywrightNotice = tw.p`text-center text-base sm:text-base mt-0 pb-6 md:mt-0 font-medium text-gray-600 mb-4`;


export default  ({ scrollToFeatures }) => {
  const navigate = useNavigate();

  const handleScrollToFeatures = () => {
  navigate('/#features-section');
  };

  const handleScrollToPricing = () => {
    navigate('/#pricing-plans');
    };

  const handleScrollToDemo = () => {
    navigate('/#video-section');
    };

  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={Logo} loading="lazy" alt="logo" title="Calculense" 
            width={"100%"}
            height={"100%"}/>
          </LogoContainer>
          <CompanyDescription>
            We revolutionizes fashion catalog creation with AI tech, eliminating the need for expensive photoshoots and models. Users can effortlessly produce stunning, high-quality catalog images that highlight clothing collections with minimal effort and maximum impact.
          </CompanyDescription>
          <SocialLinksContainer>
            <div className="cursor-pointer p-2 transform hover:scale-125 transition duration-300 mr-1">
              <a href="https://www.facebook.com/" target="_blank" title="Calculense Facebook">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" className="text-gray-600 w-5 h-5 hover:text-indigo-600" stroke-width="0.5" viewBox="0 0 24 24" loading="lazy">
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
            </div>
            <div className="cursor-pointer p-2 rounded-full transform hover:scale-125 transition duration-300 mr-1">
              <a href="https://www.instagram.com/" target="_blank" title="Calculense Instagram">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" className="text-gray-600 w-5 h-5 hover:text-indigo-600" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" loading="lazy">
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
            </div>
            <div className="cursor-pointer p-2 transform hover:scale-125 transition duration-300 mr-1">
              <a href="https://www.linkedin.com/" target="_blank" title="Calculense LinkedIn">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" className="text-gray-600 w-5 h-5 hover:text-indigo-600" stroke-width="2" viewBox="0 0 24 24">
                  <path d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77
                    0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" fill="currentColor" />
                </svg>
              </a>
            </div>
            {/* <div className="cursor-pointer p-2 transform hover:scale-125 transition duration-300 mr-1">
              <a href="https://www.youtube.com/channel/UCUKw5yZzDyqbsa6wfIa4ybw" target="_blank" title="Calculense Youtube">
                <svg fill="currentColor" className="text-gray-600 w-5 h-5 hover:text-indigo-600" viewBox="0 0 24 24">
                  <path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" />
                </svg>
              </a>
            </div> */}
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/about-us" title="About Us">About Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/contact-us" title="Contact us">Contact us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/signup" title="Get Started">Sign up</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Product</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link
                href="#"
                title="Demo Video"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToDemo();
                }}
              >
                Watch Demo
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link
                href="#"
                title="Features"
                onClick={(e) => {
                  e.preventDefault();
                  handleScrollToFeatures();
                }}
              >
                Features
              </Link>
            </LinkListItem>
            <LinkListItem>
              {/* <Link href="/" title="Pricing Plans">Pricing Plans</Link> */}
              <Link
                href="#"
                title="Pricing Plans"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleScrollToPricing(); // Call the scroll function
                }}
              >
                Pricing Plans
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Legal</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/privacy-policy" title="Privacy Policy">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/terms-of-service" title="Terms of Service">Terms of Service</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <CopywrightNotice>&copy; 2024 Calculense. All Rights Reserved.</CopywrightNotice>
    </Container>
  );
};
