import React from 'react';
import { useNavigate } from 'react-router-dom';

const Features = ({ title, description, buttonText, buttonLink, features }) => {
    const navigate = useNavigate();
  return (
    <div className='bg-gray-100 p-8'>
    <div className="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-20 max-w-screen-xl mx-auto py-10 md:py-12">
      <div className="flex flex-col lg:flex-row">
        <div className="max-w-2xl pr-16 mx-auto mb-10">
            <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-700 uppercase rounded-full bg-green-200">
                    Features
                </p>
            </div>
          <h5 className="mb-6 font-semibold text-3xl md:text-5xl leading-snug text-gray-700">{title}</h5>
          <p className="mb-2 text-base text-md text-gray-600 md:text-lg tracking-wide">{description}</p>
          <p className="mb-6 text-base text-md text-gray-600 md:text-lg tracking-wide">No models needed. No lengthy production times. Just seamless integration of advanced AI algorithms that enhance every garment, ensuring your collections shine in every frame.</p>
          <div className="flex items-center">
            <button
              type="submit"
              onClick={() => navigate(buttonLink)}
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-lg shadow-md bg-indigo-600 hover:bg-indigo-800 focus:shadow-outline focus:outline-none"
            >
              {buttonText}
            </button>
            {/* <a
              href={buttonLink}
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
            </a> */}
          </div>
        </div>
        <div className="max-w-xl grid gap-5 row-gap-5 sm:grid-cols-2">
          {features.map((feature, index) => (
            <div key={index} className="max-w-md">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
                {feature.svg}
              </div>
              <h6 className="mb-2 font-semibold text-md text-gray-600 md:text-lg tracking-wide">{feature.title}</h6>
              <p className="text-sm text-gray-600 tracking-wide">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Features;
