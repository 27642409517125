import React from 'react';
import "../../css/Loading.css";

export function BasicBoxLoader({ cubeHeight=null, cubeWidth=null, contnHeight=null }) {
    return (
        <div className={`flex justify-center item-center h-${contnHeight || 60} w-full`}>
            <div class={`sk-cube-grid my-auto h-${cubeHeight || 10} w-${cubeWidth || 10}`}>
                <div class="sk-cube sk-cube1"></div>
                <div class="sk-cube sk-cube2"></div>
                <div class="sk-cube sk-cube3"></div>
                <div class="sk-cube sk-cube4"></div>
                <div class="sk-cube sk-cube5"></div>
                <div class="sk-cube sk-cube6"></div>
                <div class="sk-cube sk-cube7"></div>
                <div class="sk-cube sk-cube8"></div>
                <div class="sk-cube sk-cube9"></div>
            </div>
        </div>
    );
}

