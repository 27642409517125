import React, { useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-2.svg";
import img1 from '../images/56000_0_test.jpg'
import img2 from '../images/6ecffa0f-2313-46d5-83de-5218456e29b1_3.png'
import img3 from '../images/image (2).webp'
import img4 from '../images/image (4).webp'
import img5 from '../images/image (11).webp'
import img6 from '../images/image (26).webp'
import img7 from '../images/image (29).webp'
import img8 from '../images/image (33).png'
import img9 from '../images/image (34).png'
import img10 from '../images/image (38).png'
import img11 from '../images/image (44).png'
import img12 from '../images/image (54).png'
import img13 from '../images/image (60).png'

const DemoVideo = ({ scrollToVideo = null }) => {
    const navigate = useNavigate();
    return (
        <div className="relative">
            <div className="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-20 mx-auto py-10 md:py-12">
                <div className="grid gap-10 md:grid-cols-2">
                    <div className="flex flex-col justify-center lg:pr-8 xl:pr-0 max-w-sm lg:max-w-lg">
                        <div className="mb-6">
                            <div className="font-semibold text-3xl md:text-4xl xl:text-5xl leading-snug max-w-3xl font-sans pb-3">
                                Revolutionize Your <br className="hidden lg:block" /><span className="text-indigo-600">Fashion catalogues</span>
                            </div>
                            <p className="my-5 lg:my-8 text-base xl:text-lg text-gray-700 tracking-wide md:pr-6 lg:pr-0">
                                Our innovative application revolutionizes the way fashion catalogues are created. No need for expensive photo shoots or hiring models. Easily produce stunning catalogue images that highlight your clothing collection with minimal effort and maximum impact.
                            </p>
                        </div>
                        <div className="flex flex-col items-center sm:flex-row justify-center md:justify-start mt-1">
                            <button onClick={() => navigate("/signup")} className="font-semibold px-8 lg:px-10 py-3 md:text-sm xl:text-base 2xl:text-xl 
                            rounded-lg bg-indigo-600 text-white hover:bg-indigo-800 focus:outline-none">
                                Try Now
                            </button>
                            <button onClick={scrollToVideo} className="text-sm my-4 mobile-l:ml-2 md:text-sm 2xl:text-xl md:mx-6 lg:my-0 font-semibold
                            tracking-wide transition border-b-2 border-transparent hover:border-indigo-600 hocus:none">
                                Watch Demo
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center justify-center -mx-4 lg:pl-8">
                        <div className="flex flex-col items-end px-3">
                            <img
                                className="object-cover object-top mb-6 rounded-xl shadow-xl shadow-indigo-200 h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                                src= {img4}
                                alt=""
                            />
                            <img
                                className="object-cover object-top w-20 h-20 rounded-xl shadow-xl shadow-indigo-200 sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                                src= {img13}
                                alt=""
                            />
                        </div>
                        <div className="px-3">
                            <img
                                className="object-cover object-top w-40 h-40 rounded-xl shadow-xl shadow-indigo-200 sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                                src= {img12}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <SvgDecoratorBlob1 className="hidden md:flex pointer-events-none absolute -left-3 lg:left-0 -bottom-20 lg:-bottom-10 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2 fill-current text-indigo-300" />
                <SvgDecoratorBlob2 className="hidden md:flex pointer-events-none absolute -top-44 -right-9 lg:-right-5 lg:-top-20 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-indigo-300" />
            </div>
        </div>
    );
};

export default DemoVideo;
