export const env_vars = {
    dev: {
        api_url: "http://localhost:8080",
        site_url: "http://localhost:3001",
        env: "dev",
        // site_key_v2: "6LeIM0weAAAAAOa9hZ_yF2pbjl65XTvL5e9cqrX1"
    },
    prod: {
        api_url: "https://api.calculense.com:444",
        site_url: "http://localhost:3001",
        env: "prod",
        // site_key_v2: "6LeIM0weAAAAAOa9hZ_yF2pbjl65XTvL5e9cqrX1"
    }
}['prod']
