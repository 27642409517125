import React from 'react';
import Marquee from 'react-fast-marquee';
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-2.svg";

const Carousel = ({ slides }) => {
    return (
    <div className="flex flex-col justify-center items-center mx-6 md:mx-12 py-10 md:py-12">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-8">
                <div>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-700 uppercase rounded-full bg-green-200">
                        Sample images
                    </p>
                </div>
                <h2 className="mb-6 font-semibold text-3xl md:text-5xl leading-snug max-w-3xl text-gray-700  md:mx-auto">
                    Effortless. Efficient. Exceptional.
                </h2>
                <p className="text-base text-md text-gray-600 md:text-lg">
                    Join us and unlock a world of possibilities for your brand. Explore our platform, experience the difference, and elevate your fashion presentations to new heights
                </p>
            </div>
        <div className="shadow-lg shadow-indigo-200 rounded-2xl w-full border">
            <Marquee gradient={false} pauseOnHover="true" speed="50" className="rounded-2xl py-3 overflow-hidden">
                {slides.map((slide, index) => (
                    <div key={index} className="flex flex-col justify-center items-center h-64 w-64 mx-5 border">
                        <img src={slide.src} className="w-full h-full object-contain" alt={slide.alt} />
                    </div>
                ))}
            </Marquee>
        </div>
        {/* <SvgDecoratorBlob1 className="pointer-events-none absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2 text-indigo-300" />
        <SvgDecoratorBlob2 className="pointer-events-none absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-green-300" /> */}
    
    </div>
    );
};

export default Carousel;




// import React from 'react';
// import Marquee from 'react-fast-marquee';

// const Carousel = ({ slides }) => {
//     return (
//     <section className="flex h-screen justify-center items-center w-screen">
//         <div className="h-[200px] shadow-lg shadow-slate-400 rounded-2xl w-1/2">
//             <Marquee gradient={false} pauseOnHover="true" className="border-t rounded-2xl border-b py-3 overflow-hidden">
//                 {slides.map((slide, index) => (
//                     <div key={index} className="flex flex-col justify-center items-center h-[150px] w-[150px] mx-5">
//                         <img src={slide.src} className="w-full h-full object-contain" alt={slide.alt} />
//                     </div>
//                 ))}
//             </Marquee>
//         </div>
//     </section>
//     );
// };

// export default Carousel;