import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { ReactComponent as SvgDecoratorBlob2 } from "../images/dot-pattern.svg";

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-indigo-500 opacity-25 absolute w-32 h-32 pb-10 transform translate-x-10 translate-y-10 `}
`;

export default () => {
  return (
    <div className="bg-gray-100 p-8">
      <div className="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-20 max-w-screen-xl mx-auto py-10 md:py-12">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-8">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-700 uppercase rounded-full bg-green-200">
              EASY STEPS
            </p>
          </div>
          {/* <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-700 sm:text-4xl md:mx-auto"> */}
          <h2 className="mb-6 font-semibold text-3xl md:text-5xl leading-snug max-w-3xl text-gray-700  md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-28 -mt-9 -ml-24 text-indigo-600 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="f51618fb-0edb-4bcb-b35a-ffc770941286"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#f51618fb-0edb-4bcb-b35a-ffc770941286)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">The</span>
            </span>{' '}
            Quickest Way to Stunning Fashion catalogues
          </h2>
          <p className="text-base text-md text-gray-600 md:text-lg tracking-wide">
            Follow these three easy steps to produce professional-quality fashion catalogue images that showcase your clothing 
            collection perfectly.
          </p>
        </div>
        <div className="grid gap-8 row-gap-0 md:grid-cols-3">
          <div className="relative text-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-indigo-500 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
                  <path fill-rule="evenodd" d="M11.47 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06l-3.22-3.22V16.5a.75.75 0 0 1-1.5 0V4.81L8.03 8.03a.75.75 0 0 1-1.06-1.06l4.5-4.5ZM3 15.75a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
                </svg>

              </div>
              <h6 className="mb-2 text-2xl font-bold">Step 1</h6>
              <p className="max-w-md md:h-28 lg:h-24 xl:h-16 mb-6 text-md text-gray-700 sm:mx-auto">
                Start by uploading a high-quality image of the clothing item you want to feature.
              </p>
              <div class="relative flex flex-col items-center justify-center p-4 w-44 h-16 rounded-2xl">
                <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-300 rounded-xl -rotate-2 -z-1"></div>
                <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-400 rounded-xl rotate-2 "></div>
                <div class="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl"></div>
                <span className="z-10 inline-flex items-center text-md lg:text-lg font-semibold transition-colors duration-200 text-indigo-600">Upload Image</span>
              </div>
            </div>
            <div className="top-0 right-0 flex items-center justify-center h-24 md:-mr-8 md:absolute">
              <svg
                className="w-8 text-gray-700 transform rotate-90 md:rotate-0"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                />
                <polyline
                  fill="none"
                  strokeMiterlimit="10"
                  points="15,5 22,12 15,19 "
                />
              </svg>
            </div>
          </div>
          <div className="relative text-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
                  <path fill-rule="evenodd" d="M12 1.5a.75.75 0 0 1 .75.75V4.5a.75.75 0 0 1-1.5 0V2.25A.75.75 0 0 1 12 1.5ZM5.636 4.136a.75.75 0 0 1 1.06 0l1.592 1.591a.75.75 0 0 1-1.061 1.06l-1.591-1.59a.75.75 0 0 1 0-1.061Zm12.728 0a.75.75 0 0 1 0 1.06l-1.591 1.592a.75.75 0 0 1-1.06-1.061l1.59-1.591a.75.75 0 0 1 1.061 0Zm-6.816 4.496a.75.75 0 0 1 .82.311l5.228 7.917a.75.75 0 0 1-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 0 1-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 0 1-1.247-.606l.569-9.47a.75.75 0 0 1 .554-.68ZM3 10.5a.75.75 0 0 1 .75-.75H6a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 10.5Zm14.25 0a.75.75 0 0 1 .75-.75h2.25a.75.75 0 0 1 0 1.5H18a.75.75 0 0 1-.75-.75Zm-8.962 3.712a.75.75 0 0 1 0 1.061l-1.591 1.591a.75.75 0 1 1-1.061-1.06l1.591-1.592a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
                </svg> */}

              </div>
              <h6 className="mb-2 text-2xl font-bold">Step 2</h6>
              <p className="max-w-md md:h-28 lg:h-24 xl:h-16 mb-6 text-md text-gray-700 sm:mx-auto">
                Pick from our diverse range of models that best represent your brand’s style.
              </p>
              <div class="relative flex flex-col items-center justify-center p-4 w-44 h-16 rounded-2xl">
                <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-300 rounded-xl -rotate-2 -z-1"></div>
                <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-400 rounded-xl rotate-2 "></div>
                <div class="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl"></div>
                <span className="z-10 inline-flex items-center text-md lg:text-lg font-semibold transition-colors duration-200 text-indigo-600">Choose Model</span>
              </div>
            </div>

            <div className="top-0 right-0 flex items-center justify-center h-24 md:-mr-8 md:absolute">
              <svg
                className="w-8 text-gray-700 transform rotate-90 md:rotate-0"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <line
                  fill="none"
                  strokeMiterlimit="10"
                  x1="2"
                  y1="12"
                  x2="22"
                  y2="12"
                />
                <polyline
                  fill="none"
                  strokeMiterlimit="10"
                  points="15,5 22,12 15,19 "
                />
              </svg>
            </div>
          </div>

          <div className="relative text-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
                {/* <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
                  <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                </svg>

              </div>
              <h6 className="mb-2 text-2xl font-bold">Step 3</h6>
              <p className="max-w-md md:h-28 lg:h-24 xl:h-16 mb-6 text-md text-gray-700 sm:mx-auto">
                With one click, create a stunning catalogue image ready for download in high resolution.
              </p>
              <div class="relative flex flex-col items-center justify-center p-4 w-44 h-16 rounded-2xl">
                <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-300 rounded-xl -rotate-2 -z-1"></div>
                <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-400 rounded-xl rotate-2 "></div>
                <div class="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl"></div>
                <span className="z-10 inline-flex items-center text-md lg:text-lg font-semibold transition-colors duration-200 text-indigo-600">Create catalogue</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};