import React from "react";
import business_svg from '../images/business_growth.svg';

const Business = () => {
    return (
        <div className="bg-gray-100 p-8">
            <div className="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-20 max-w-screen-xl mx-auto py-10 md:py-12">
                <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
                    <div className="flex flex-col justify-center">
                        <div className="max-w-xl mb-6">
                            <div>
                                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-700 uppercase rounded-full bg-green-200">
                                    Business
                                </p>
                            </div>
                            <h2 className="mb-6 font-semibold text-3xl md:text-4xl xl:text-5xl leading-snug max-w-3xl text-gray-700  md:mx-auto">
                                Supercharge Business
                                <br className="hidden md:block" />
                                {' '}Growth,{' '}
                                <span className="relative px-0">
                                    <div className="hidden md:block absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-green-300" />
                                    <span className="relative inline-block text-indigo-600">
                                        10x Cheaper
                                    </span>
                                </span>
                            </h2>
                            <p className="text-base text-md text-gray-600 md:text-lg">
                                Cut costs and save time with AI-powered catalog creation. Experience professional quality without the expense of traditional photoshoots. Transform your fashion business with stunning, high-quality catalogs created in minutes.
                            </p>
                        </div>
                        <p className="mb-4 text-sm font-bold tracking-widest uppercase">
                            Advantages
                        </p>
                        <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
                            <ul className="space-y-3">
                                <li className="flex">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-indigo-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    <span className="mr-5">Significant Cost Reduction</span>
                                    {/* <div className="animate-bounce uppercase flex items-center px-2 text-xs bg-green-200 rounded-full tracking-wider font-semibold text-green-700">Free</div> */}
                                </li>
                                <li className="flex">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-indigo-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    Time Efficiency
                                </li>
                                <li className="flex">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-indigo-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    Enhanced Brand Consistency
                                </li>
                            </ul>
                            <ul className="space-y-3">
                                <li className="flex">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-indigo-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    Scalable Solutions
                                </li>
                                <li className="flex">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-indigo-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    Global Reach
                                </li>
                                <li className="flex">
                                    <span className="mr-1">
                                        <svg
                                            className="w-5 h-5 mt-px text-indigo-400"
                                            stroke="currentColor"
                                            viewBox="0 0 52 52"
                                        >
                                            <polygon
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fill="none"
                                                points="29 13 14 29 25 29 23 39 38 23 27 23"
                                            />
                                        </svg>
                                    </span>
                                    Enhanced Customer Experience
                                </li>
                            </ul>
                        </div>
                        <a
                            href="/signup"
                            aria-label=""
                            className="mt-6 inline-flex items-center font-semibold transition-colors duration-200 text-indigo-600 hover:text-indigo-700"
                        >
                            Get Started
                            <svg
                                className="inline-block w-3 ml-2 mt-1"
                                fill="currentColor"
                                viewBox="0 0 12 12"
                            >
                                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                            </svg>
                        </a>
                    </div>
                    <div className="flex flex-col justify-center">
                        <img
                            className=" w-full h-56 rounded-xl shadow-lg border shadow-indigo-200 sm:h-96 mt-2 xl:mt-4 p-1"
                            src={business_svg}
                            alt="One-Off Session"
                            loading="lazy"
                            title="book one off session with mentors"
                            width={"100%"}
                            height={"100%"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business;
