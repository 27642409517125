import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router";
import UserAvatar from './images/img/placeholder_avatar.png';
import LogoIcon from './images/img/calculense_logo.png';
import { getLoggedInUser } from "../hooks/useAuth";
import { handleSignOut } from "../hooks/useAuth";
import { env_vars } from "../env";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import user_img from './images/img/placeholder_avatar.png'

function SimpleMenu({ expandButton, menuList }) {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const closeMenu = useCallback(() => {
        setIsOpen(false);
    }, [])

    useOutsideAlerter(menuRef, closeMenu);

    return (
        <div className="relative flex flex-col" ref={menuRef}>
            {expandButton(isOpen, setIsOpen)}

            {/* dropdown */}
            {
                isOpen &&
                <div className="p-2 mobile-s:right-5 mobile-s:top-11 mobile-m:-right-2 mobile-l:right-4 md:right-6 md:top-12 lg:top-0 lg:mt-10 lg:-right-6 w-52 bg-white border rounded-lg shadow-sm space-y-4 absolute z-40">
                    <ul className="">
                        {menuList(isOpen, setIsOpen)}
                    </ul>
                </div>
            }
        </div>
    )
}

function UserMenu({ user }) {
    // const { type, user_details } = user;
    const user_name = user.name;

    const expandButton = (isOpen, setIsOpen) => {
        return (
            <button onClick={() => setIsOpen(prev => !prev)} className="flex items-center justify-center  lg:justify-between w-full group pl-4 mb-1 focus:outline-none">
                <div className="">
                    <img className="w-6 h-6 md:w-6 md:h-6 object-cover object-center rounded-full" src={user_img || "#"} width="24" height="24" alt="User" loading="lazy" />
                    {/* <img className="rounded-full w-8 h-8 object-cover object-center border border-gray-600" src={img_url || UserAvatar} width="32" height="32" alt={user_name || "Sachleen"} loading="lazy" title="profile_pic"/> */}
                </div>

                <div className="flex items-center text-gray-700  ml-3">
                    <span className="truncate overflow-hidden w-20 md:w-24 capitalize text-lg lg:text-sm font-medium group-hover:text-gray-800">{user_name || "Sachleen"}</span>
                    <svg className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400" viewBox="0 0 12 12">
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                </div>
            </button>
        )
    }

    const menuList = (isOpen, setIsOpen) => {
        return (
            <>
                <li>
                    <a
                        href={`/dashboard/dashboardHome`}
                        className="lg:flex text-md my-2 px-2 lg:text-sm font-semibold tracking-wide
                        pb-1 hover:text-indigo-600 
                        flex items-center justify-start focus:outline-none"
                        title="Dashboard"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>

                        <span className="pl-2 text-gray-700">Dashboard</span>
                    </a>
                </li>
                <li className="">
                    <a
                        onClick={handleSignOut}
                        className="cursor-pointer lg:flex text-md my-2 px-2 lg:text-sm font-semibold tracking-wide
                        pb-1 hover:text-indigo-600
                        flex items-center justify-start"
                        title="Sign Out"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 hover:text-indigo-600 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        <span className="pl-2 text-gray-700">Sign Out</span>
                    </a>
                </li>
            </>
        )
    }

    return (
        <SimpleMenu expandButton={expandButton} menuList={menuList} />
    )
}

function GenericHeader1({ scrollToFeatures = null, scrollToPricing = null}) {
    let currLocation = useLocation();
    const [user, setUser] = useState(null);
    const [error, setError] = useState({ status: false, msg: "" });
    const [loading, setLoading] = useState({ status: false, type: "BasicBoxLoader" });

    useEffect(() => {
        try {
            let loggedInUser = getLoggedInUser();
            if (loggedInUser === null) {
                setError({ status: true, msg: "No user Logged in!" })
            } else {
                setUser(loggedInUser);
            }
            setLoading(prev => ({ ...prev, status: false }));
        } catch {
            console.log("Failed to fetch")
        }
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    const menuRef = useRef(null);

    const closeMenu = useCallback(() => {
        setIsOpen(false);
    }, [])

    useOutsideAlerter(menuRef, closeMenu);

    return (
        <div className="border-b sticky top-0 bg-white z-30">
            <div class="mx-auto px-6 lg:px-8 bxl:px-8 xl:px-20  sm:max-w-xl md:max-w-full lg:max-w-screen-xl xl:max-w-screen-2xl text-gray-700 py-4 mt-0">
            {/* max-w-screen-xl xl:max-w-screen-2xl */}
                <div class="relative flex items-center justify-between">
                    <a
                        href="/"
                        aria-label="Calculense"
                        title="Calculense"
                        class="inline-flex items-center"
                    >
                        <img className="w-40 focus:outline-none" src={LogoIcon} alt="logo" title="Calculense" 
                        width={"100%"}
                        height={"100%"}/>
                    </a>
                    <ul class=" items-center lg:flex" >
                        <li>
                            {
                                currLocation.pathname !== null && (currLocation.pathname === "/" || currLocation.pathname === "/home") ?
                                    <a
                                        onClick={scrollToFeatures}
                                        className="cursor-pointer hidden lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                    pb-1 border-b-2 border-transparent hover:border-indigo-600 hocus:text-indigo-600"
                                    title="Features"
                                    >
                                        Features
                                    </a>
                                    :
                                    <a
                                        href="/#features-section"
                                        className="hidden lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                    pb-1 border-b-2 border-transparent hover:border-indigo-600 hocus:text-indigo-600"
                                    title="Features"
                                    >
                                        Features
                                    </a>
                            }
                        </li>
                        <li>
                            {
                                currLocation.pathname !== null && (currLocation.pathname === "/" || currLocation.pathname === "/home") ?
                                    <a
                                        onClick={scrollToPricing}
                                        className="cursor-pointer hidden lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                    pb-1 border-b-2 border-transparent hover:border-indigo-600 hocus:text-indigo-600"
                                    title="Pricing"
                                    >
                                        Pricing
                                    </a>
                                    :
                                    <a
                                        href="/#pricing-plans"
                                        className="hidden lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                    pb-1 border-b-2 border-transparent hover:border-indigo-600 hocus:text-indigo-600"
                                    title="Pricing"
                                    >
                                        Pricing
                                    </a>
                            }
                        </li>
                        <li>
                            <a
                                href="/contact-us"
                                className="hidden lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                pb-1 border-b-2 border-transparent hover:border-indigo-600 hocus:text-indigo-600"
                                title="Contact Us"
                            >
                                Contact Us
                            </a>
                        </li>
                        {
                            user !== null && user.name !== undefined && user.name !== null  ?
                                <div className="hidden lg:flex">
                                    <UserMenu user={user} />
                                </div>
                                :
                                <>
                                    <li>
                                        <a
                                            href="/login"
                                            className="hidden lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                        pb-1 border-b-2 border-transparent hover:border-indigo-600 hocus:text-indigo-600"
                                        title="Login"
                                        >
                                            Login
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/signup"
                                            className="hidden lg:flex lg:mx-0
                                                px-4 py-2.5 text-lg lg:text-sm rounded-lg font-semibold bg-indigo-600 text-white
                                                hocus:bg-indigo-800 hocus:text-gray-100 
                                                border-b-0"
                                            aria-label="Sign up"
                                            title="Sign up"
                                        >
                                            Sign up
                                        </a>
                                    </li>
                                </>
                        }
                        <li>
                            <button
                                onClick={() => setIsOpen(prev => !prev)}
                                class="lg:hidden p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-indigo-50"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        </li>
                    </ul>

                    {/* Mobile */}
                    <div class="lg:hidden z-10 fixed top-0 inset-x-0">
                        {
                            isOpen &&
                            <div class="absolute top-0 right-0 p-4">
                                <div class="p-5 bg-white border rounded-lg shadow-sm mobile-s:w-60 mobile-m:w-52 mobile-l:w-56 md:w-64">
                                    <div class="flex items-center justify-between">
                                        <div></div>

                                        <div>
                                            <button
                                                onClick={() => setIsOpen(prev => !prev)}
                                                class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                            >
                                                <svg class="h-5 w-5 text-gray-600" viewBox="0 0 24 24">
                                                    <path
                                                        fill="currentColor"
                                                        d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <nav className="text-center">
                                        <ul class="space-y-4" ref={menuRef}>
                                            <li>
                                                {
                                                    currLocation.pathname !== null && (currLocation.pathname === "/" || currLocation.pathname === "/home") ?
                                                        <a
                                                            onClick={scrollToFeatures}
                                                            className="lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                                        pb-1 hover:text-indigo-600"
                                                        title="Features"
                                                        >
                                                            Features
                                                        </a>
                                                        :
                                                        <a
                                                            href="/#features-section"
                                                            className="lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                                        pb-1 hover:text-indigo-600"
                                                        title="Features"
                                                        >
                                                            Features
                                                        </a>
                                                }
                                            </li>
                                            <li>
                                                {
                                                    currLocation.pathname !== null && (currLocation.pathname === "/" || currLocation.pathname === "/home") ?
                                                        <a
                                                            onClick={scrollToPricing}
                                                            className="lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                                        pb-1 hover:text-indigo-600"
                                                        title="Features"
                                                        >
                                                            Pricing
                                                        </a>
                                                        :
                                                        <a
                                                            href="/#pricing-plans"
                                                            className="lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                                        pb-1 hover:text-indigo-600"
                                                        title="Features"
                                                        >
                                                            Pricing
                                                        </a>
                                                }
                                            </li>
                                            <li>
                                                <a
                                                    href="/contact-us"
                                                    className="lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                                    pb-1 hover:text-indigo-600"
                                                    title="Contact Us"
                                                >
                                                    Contact Us
                                                </a>
                                            </li>
                                            {
                                                user !== null && user.name !== undefined  && user.name !== null ?
                                                    <UserMenu user={user} />
                                                    :
                                                    <>
                                                        <li>
                                                            <a
                                                                href="/login"
                                                                className="lg:flex text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300
                                                                    pb-1 hover:text-indigo-600"
                                                                    title="Login"
                                                            >
                                                                Login
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="/signup"
                                                                className="lg:flex lg:mx-0 pt-2
                                                                    px-4 py-2.5 text-lg lg:text-sm rounded-lg font-semibold bg-indigo-600 text-white
                                                                    hocus:bg-indigo-800 hocus:text-gray-100 
                                                                    border-b-0"
                                                                aria-label="Sign up"
                                                                title="Sign up"
                                                            >
                                                                Get Started
                                                            </a>
                                                        </li>
                                                    </>
                                            }
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        }

                    </div>

                </div>
            </div>
        </div>
    );
};


export default GenericHeader1;