import {format} from 'date-fns';
import { env_vars } from '../env';
import axios from 'axios';

export const axios_json = axios.create({
    baseURL: env_vars.api_url,
    headers: {
        'Content-Type': 'application/json'
    },
});

export const axios_multipart_form = axios.create({
    baseURL: env_vars.api_url,
    headers: {
        'Content-Type': 'multipart/form-data'
    },
});

export const axios_form = axios.create({
    baseURL: env_vars.api_url,
    headers: {
        'Content-Type': 'multipart/form-data'
    },
});

// Getting greeting based on time of day
export function getGreeting() {
    let currentDateTime = new Date();
    let currentHour = currentDateTime.getHours();
    if (currentHour >= 5 && currentHour < 12) {
        return {msg: "Good Morning", flag: 1};
    } else if (currentHour >= 12 && currentHour < 17) {
        return {msg: "Good After Noon", flag: 2};
    } else if (currentHour >= 17 && currentHour < 23) {
        return {msg: "Good Evening", flag: 3};
    } else {
        return {msg: "Good Night", flag: 4};
    }
}

// Convert a number to a string with commas taking into account decimal point

export function numberWithCommasDecimal(x) {
    if (! x) {
        return null;
    }
    return x.toString().toLocaleString('en-US');
}

// get date msg
export function getDateMsg(date) {
    let curDateMsg = '';
    if ([11, 12, 13].includes(parseInt(date))) {
        return 'th';
    }
    if (date%10 === 1) {
        curDateMsg = 'st';
    } else if (date%10 === 2) {
        curDateMsg = 'nd';
    } else if (date%10 === 3) {
        curDateMsg = 'rd';
    } else {
        curDateMsg = 'th';
    }
    return curDateMsg;
}

// get month name
export function getMonthName(month, len) {
    let monthsLong = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthsShortened = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (len === "long") {
        return monthsLong[month];
    } else {
        return monthsShortened[month];
    }
}

// get 12 hr hours
export const get12Hr = hours => hours%12 === 0 ? 12 : hours%12;

// get am pm
export const getAmPm = hours => hours > 12 ? 'pm' : 'am';

export function convertTimeStamp(date) {
    let tempDate = new Date(date);
    let curDate = tempDate.getDate();
    let curDateMsg = getDateMsg(curDate);
    let curMonthName = getMonthName(tempDate.getMonth());
    let curHours = get12Hr(tempDate.getHours());
    let curAmPm = getAmPm(tempDate.getHours());
    let curMin = tempDate.getMinutes().toString().padStart(2, '0');
    return `${curDate}${curDateMsg} ${curMonthName}, ${curHours}:${curMin} ${curAmPm.toUpperCase()}`;
}

export function convertUtcToIst(utcDateString) {
    const utcDate = new Date(utcDateString);
    if (isNaN(utcDate.getTime())) {
      console.error("Invalid UTC Date String");
      return "Invalid Date";
    } else {
      const istOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
      const istDate = new Date(utcDate.getTime() + istOffset);
      const year = istDate.getFullYear();
      const month = String(istDate.getMonth() + 1).padStart(2, '0');
      const day = String(istDate.getDate()).padStart(2, '0');
      const hours = String(istDate.getHours()).padStart(2, '0');
      const minutes = String(istDate.getMinutes()).padStart(2, '0');
      const seconds = String(istDate.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
  }

// get next date
export function getNextDate(date, days) {
    // date - date object
    // days - days after which date is required
    let tomorrow = new Date();
    return new Date(tomorrow.setDate(date.getDate() + days));
}

// get days overdue
export function getDaysOverDue(date) {
    let currentDate = new Date();
    let targetDate = new Date(date);

    return (currentDate - targetDate)/(3600*24*1000);
}