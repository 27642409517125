import React, { useState, useEffect } from "react";
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { SectionDescription } from "../misc/Typography.js";
import { useNavigate } from 'react-router-dom';
import { Container } from "../misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-7.svg";
import { useSpring, animated } from '@react-spring/web';

const Pricing = ({
  subheading = "Pricing",
  heading = "Affordable Plans to Transform Your Fashion catalogues",
  description = "Flexible pricing for every business size. Start your free trial and create stunning catalogues effortlessly with our AI-powered platform.",
  plans = null,
  primaryButtonText = "Buy Now",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ],
  discount = 20
}) => {
  const defaultPlans = [
    {
      name: "Starter",
      durationPrices: ["Rs.0", "Rs.0"],
      mainFeature: "Best option for personal use & for your next project.",
      features: ["10 images", "4 models", "abc", "xyz"],
      absent_features: ["unlimited usage", "unlimited models"]
    },
    { 
      name: "Pro Plan",
      durationPrices: ["Rs.1,999", "Rs.19,999"],
      mainFeature: "Relevant for multiple users, extended & premium support.",
      features: ["500 images", "12 models", "abc", "xyz"],
      absent_features: ["unlimited usage", "unlimited models"],
      featured: true
    },
    { 
      name: "Business Plan",
      durationPrices: ["Rs.3,999", "Rs.44,999"],
      mainFeature: "Best for large scale uses and extended redistribution rights.",
      features: ["Unlimited usage", "Unlimited models", "abc", "xyz", "test", "test2"],
      absent_features: []
    }
  ];

  const navigate = useNavigate();

  const targetDate = new Date('2024-09-10T00:00:00'); // target date here
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;
    return difference > 0 ? difference : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  if (!plans) plans = defaultPlans;
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const isLoggedIn = () => {
    // Replace this with your actual authentication logic
    // return localStorage.getItem("userToken"); // Assuming token is stored in localStorage
    return true; // Assuming token is stored in localStorage
  };

  const handleBuyNowClick = (plan) => {
    if (isLoggedIn()) {
      console.log("activeDurationIndex_landing: ", activeDurationIndex)
      navigate("/payment", { state: { plan, discount, activeDurationIndex } });
    } else {
      navigate("/login");
    }
  };

  return (
    <Container>
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          {subheading && <SubheadingBase className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-700 uppercase rounded-full bg-green-200">{subheading}</SubheadingBase>}
          <SectionHeading className="mb-3 font-semibold text-3xl md:text-5xl leading-snug max-w-3xl text-gray-700 mx-auto">{heading}</SectionHeading>
          {description && <SectionDescription className="text-base md:text-lg text-gray-600 tracking-wide text-center mx-auto">{description}</SectionDescription>}
          
          
          {/* toggle for yearly/monthly*/}

          {/* <div className="block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-6 mx-auto">
            {planDurations.map((planDuration, index) => (
              <button
                key={index}
                onClick={() => setActiveDurationIndex(index)}
                className={`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300 ${activeDurationIndex === index ? 'bg-indigo-600 text-white' : ''}`}
              >
                {planDuration.switcherText}
              </button>
            ))}
          </div> */}
        </div>

        <div className="flex flex-wrap mx-auto max-w-screen-md lg:max-w-screen-lg justify-start items-start gap-6 lg:gap-0 lg:justify-around lg:items-center border-2 rounded-xl mt-10 p-8">
          <div className="text-left">
            <h2 className="text-3xl font-semibold text-green-600 mb-4 tracking-wide">
              Free for Now !
            </h2>
            <h3 className="text-xl font-medium text-gray-600 mb-4 tracking-wide">
              Offer ends in:
            </h3>
            <div className="text-center text-3xl font-semibold text-white bg-indigo-600 tracking-wide p-3 w-auto md:w-2/3 rounded-xl">
              <animated.div>{formatTime(timeLeft)}</animated.div>
            </div>
            <p className="mt-2 text-gray-600 tracking-wide">
              Enjoy full access to all our features and services at no cost.
            </p>
          </div>
          <div>
            <ul className="space-y-4">
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span>
                Unlimited Catalog Creation
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span>
                AI-Powered Customization
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span>
                Professional-Grade Outputs
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span>
                Priority Support
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✔</span>
                Regular Updates
              </li>
            </ul>
          </div>
        </div>

        {/* pricing categories cards */}
        {/* <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center relative mx-auto max-w-3xl xl:max-w-6xl mt-12 space-y-8 md:space-y-0 md:space-x-6 lg:space-x-8">
          {plans.map((plan, index) => {
            const originalPrice = parseFloat(plan.durationPrices[activeDurationIndex].replace('Rs.', '').replace(',', ''));
            const isStarterPlan = plan.name === "Starter";
            const discountAmount = isStarterPlan ? 0 : (originalPrice * discount) / 100;
            const discountedPrice = originalPrice - discountAmount;
            return (
              <div key={index} className={`w-full md:w-1/3 lg:w-1/3 py-8 px-6 mb-8 md:mb-0 text-center rounded-3xl shadow-xl relative text-gray-900 flex flex-col justify-between ${plan.featured ? 'border-2 border-indigo-400 shadow-xl' : 'border-2'}`}>
                <div className="flex flex-col leading-relaxed flex-grow">
                  <span className={` ${plan.featured ? 'hidden' : 'name font-semibold text-gray-800 text-xl tracking-wide flex justify-start items-center px-6'}`}>{plan.name}</span>
                  <div className={` ${plan.featured ? 'flex flex-row justify-between items-center px-6' : 'hidden'}`}>
                    <span className="name font-semibold text-xl tracking-wide text-indigo-600">{plan.name}</span>
                    <p className="inline-block px-3 py-px text-xs font-semibold tracking-wider rounded-full text-indigo-700 uppercase bg-indigo-200">
                      Most Popular
                    </p>
                  </div>
                  <span className="mainFeature text-gray-500 text-sm font-medium tracking-wide text-start flex justify-start px-6 py-3">{plan.mainFeature}</span>
                  <span className="priceAndDuration flex flex-col justify-start items-start px-6">
                    {isStarterPlan ? (
                      <div className="original-price text-md line-through text-gray-500 mb-1">Rs. 500</div>
                    ) : (
                      discount > 0 && (
                        <div className="original-price text-md line-through text-gray-500 mb-1">Rs. {originalPrice.toFixed(2)}</div>
                      )
                    )}
                    <div className="flex justify-between items-center gap-2">
                      <div className="price font-bold text-2xl sm:text-3xl my-1 text-gray-600 tracking-wide">Rs. {discountedPrice.toFixed(2)}</div>
                      <div className="pt-2 duration lowercase text-gray-500 font-medium text-sm tracking-widest"> / {planDurations[activeDurationIndex].text}</div>
                    </div>
                    {isStarterPlan ? (
                      <div className="free-trial text-sm text-green-600 font-medium pt-1">This plan is completely free to try</div>
                    ) : (
                      discount > 0 && (
                        <div className="discount text-sm text-green-600 font-medium pt-1">You Save {discount}%</div>
                      )
                    )}
                  </span>
                </div>
                <div className="px-4 pb-8 mt-6">
                  <button 
                    onClick={() => handleBuyNowClick(plan)} 
                    className={`px-8 py-2 rounded-md text-center font-semibold focus:outline-none tracking-wider w-full text-sm hover:shadow-lg ${plan.featured ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white' : 'bg-white text-indigo-600 border border-indigo-600'}`}
                  >
                    {primaryButtonText}
                  </button>
                </div>
                <div className="text-sm mt-3 space-y-4 text-left px-6">
                  {plan.features.map((feature, index) => (
                    <div key={index} className="flex justify-start items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                      </svg>
                      <span className="feature font-medium text-gray-500">
                        {feature}
                      </span>
                    </div>
                  ))}
                  {plan.absent_features.map((feature, index) => (
                    <div key={index} className="flex justify-start items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-red-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M6.293 4.293a1 1 0 011.414 0L10 6.586l2.293-2.293a1 1 0 111.414 1.414L11.414 8l2.293 2.293a1 1 0 01-1.414 1.414L10 9.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 8 6.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                      </svg>
                      <span className="feature font-medium text-gray-500">
                        {feature}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div> */}

      </div>
      <SvgDecoratorBlob1 className="pointer-events-none absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2 text-indigo-300" />
      <SvgDecoratorBlob2 className="pointer-events-none absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-green-300" />
    </Container>
  );
};

export default Pricing;
