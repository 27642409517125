import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/youtube';
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-2.svg";
// import vid from "./demo_video.mp4"
import vid from "./calculense_demo.mp4"

const DemoVideo = ({ videoUrl }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    if (videoUrl) {
      setIsVideoLoaded(true);
    }
  }, [videoUrl]);

  return (
    <div className="relative">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-8 md:mx-auto sm:text-center lg:max-w-2xl md:mb-10">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-00 uppercase rounded-full bg-green-200">
              DEMO
            </p>
          </div>
          <h2 className="mb-6 font-semibold text-3xl md:text-5xl leading-snug max-w-3xl text-gray-700 md:mx-auto">
            <span className="relative inline-block">
              <span className="relative">Get</span>
            </span>{' '}
            a Sneak Peek with Our Demo Video
          </h2>
        </div>
        <div className="mx-auto lg:max-w-2xl">
        <video autoPlay loop muted className="object-fill bg-gray-200 w-full h-56 rounded-2xl  border-2 shadow-lg sm:h-96">
                  <source src={vid} type="video/mp4" />
                </video>
          {/* <div className="relative w-full transition-shadow duration-300 hover:shadow-xl">
            {isVideoLoaded ? (
              <ReactPlayer
                url={videoUrl}
                playing
                loop
                muted
                controls={false}
                width="100%"
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      showinfo: 0,
                      rel: 0,
                      fs: 0,
                      disablekb: 1,
                      iv_load_policy: 3,
                    },
                  },
                }}
                className="rounded shadow-lg"
              />
            ) : (
              <img
                className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:h-96"
                src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                alt=""
              />
            )}
          </div> */}
        </div>
        <SvgDecoratorBlob2 className="hidden md:flex pointer-events-none absolute -right-4 top-10 h-64 w-64 opacity-50 transform translate-x-2/3 translate-y-1/2 fill-current text-indigo-300" />
        <SvgDecoratorBlob2 className="hidden md:flex pointer-events-none absolute -left-64 bottom-52 h-64 w-64 opacity-50 transform translate-x-1/3 translate-y-1/2 fill-current text-indigo-300" />
      </div>
    </div>
  );
};

export default DemoVideo;
