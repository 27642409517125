import axios from "axios";
import { LocalStorageName } from "../App";
import { env_vars } from '../env';
import { axios_form, axios_json } from "../utils/Utils";
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

export const sendEmailResetEmail = () => {
  console.log("sends email with otp to user's new email");
}

export const setNewEmail = () => {
  console.log("sends email with otp to user's new email");
}

export const getLoggedInUser = () => {
  const localStorageData = JSON.parse(window.localStorage.getItem(LocalStorageName));
  if (!localStorageData) {
    console.log("Something Bad Happened!");
    return null;
  } else {
    return localStorageData;
  }
}

export const signin = (values, cbSuccess, cbFailure) => {
  let { email, password } = values;
  const login_data = {
    email: email,
    password: password
  }
  axios.post(`${env_vars.api_url}/login`, login_data, {
    method: "post",
    headers: { 'Content-Type': 'application/json' }
  }).then(function (response) {
    console.log('Login successful. Response:', response.data);
    window.localStorage.removeItem(LocalStorageName);
    window.localStorage.setItem(LocalStorageName, JSON.stringify({
      email: response?.data?.data?.email,
      token: response?.data?.data?.token,
      name: response?.data?.data?.firstName,
      credits: response?.data?.data?.credit
    }));
    cbSuccess(response);
  }).catch(function (error) {
    cbFailure(error);
    console.error('Error:', error.response?.data);
  });
};

export const signup = async (values) => {
  let { firstName, lastName, email, password } = values;
  const signup_data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password
  };

  let res = await axios_form.post('/user', signup_data, {
    headers: { 'Content-Type': 'application/json' }
  })
  .catch(error => {
    // console.log("error: ", error)
    // console.log('Error:', error.response ? error.response.data : error.message);
    return error.response
  });
  return res;
};

// const navigate = useNavigate();
export const signout = (cbSuccess, cbFailure) => {
  console.log('logging out the user');
  window.localStorage.removeItem(LocalStorageName);
  // cbSuccess();
  if (cbSuccess) {
    cbSuccess();
  }
};

const cbSuccess = (response) => console.log("Successfully logged out!"); 
const cbFailure = (error) => console.log("Unable to log out!");

export const handleSignOut = () => {
  signout(cbSuccess, cbFailure);
  window.location.href = '/';
}

export const deactivate = () => {
  console.log('Deactivating the user');
  console.log('send api call to deactivate user');
  signout(cbSuccess, cbFailure);
  window.location.href = '/';
};

export const sendPasswordResetEmail = (email) => {
  console.log('ask backend to send password reset email: ', email);
};

export const confirmPasswordReset = async (email, type, code) => {
  let res = null;
  try {
    res = await axios_json.post('/forget-pass-otp-match', {
      email: email, type: type, otp: code
    });
  } catch {
    return { result: false };
  }
  return res.data.match_status;
};

export const setNewPassowrd = async (email, password, type, otpToken) => {
  let res = null;
  try {
    res = await axios_json.post('/pass-change', {
      email: email, type: type, token: otpToken, passw: password 
    });
  } catch {
    return { result: false };
  }
  return res.data;
}

export const validateEmail = async (email, type) => {
  let res = null;
  try {
    res = await axios_json.post('/forget-pass-user-check', {
      email: email, type: type
    });
  } catch {
    return false;
  }
  return res.data.result;
}

export const isLoggedIn = () => {
  const localStorageData = JSON.parse(window.localStorage.getItem(LocalStorageName));
  if (!localStorageData) {
    return false;
  }
  console.log(localStorageData);
  const { token } = localStorageData;
  return token ? true : false;
}
