import {
  Routes,
  Route,
} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import jwt from 'jwt-decode';

import PrivateRoute from './components/PrivateRoute';
// import ReactGA from 'react-ga';
import RouteChangeTracker from './components/RouteChangeTracker';
import SnackBarComp from './components/snackbar/SnackBarComp';
import { SnackbarContext } from './components/snackbar/SnackBarComp';
import { BasicBoxLoader } from "./components/pages/LoadingPage";
import LandingPage from './components/pages/LandingPage'
import CheckTokenExpire from './hooks/useTokenExpire';

// const ForgotPassword = React.lazy(() => import('./components/Pages/ForgotPassword'));
const TermsOfService = React.lazy(() => import('./components/pages/TermsOfService'));
const PrivacyPolicy = React.lazy(() => import('./components/pages/PrivacyPolicy'));
const ContactUs = React.lazy(() => import('./components/pages/ContactUs'));
const AboutUs = React.lazy(() => import('./components/pages/AboutUs'));
const DashboardPage = React.lazy(() => import('./components/pages/DashboardPage'));
const Signup = React.lazy(() => import('./components/Signup'));
const Login = React.lazy(() => import('./components/Login'));
const Payment = React.lazy(() => import('./components/pages/PricingPage'));

// const TRACKING_ID = "UA-217334345-1"; // TODO: make this env variable
// ReactGA.initialize(TRACKING_ID);

export const LocalStorageName = 'calculense_localstorage_data';

function App() {  
  const [errorPopup, setErrorPopup] = useState({ status: false, msg: "", severity: "" });
  // useTokenExpire();
  return (
    <BrowserRouter>
      <SnackbarContext.Provider value={{ errorPopup, setErrorPopup }}>
        <SnackBarComp errorPopup={errorPopup} setErrorPopup={setErrorPopup} />
        {/* <RouteChangeTracker /> */}
        <CheckTokenExpire />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/login" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <Login />
            </React.Suspense>
          } />
          <Route path="/signup" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <Signup />
            </React.Suspense>
          } />
          <Route path="/dashboard/*" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <DashboardPage />
            </React.Suspense>
          } />
          <Route path="/payment" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <Payment />
            </React.Suspense>
          } />
          <Route path="/about-us" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <AboutUs />
            </React.Suspense>
          } />
          <Route path="/contact-us" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <ContactUs />
            </React.Suspense>
          } />
          <Route path="/terms-of-service" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <TermsOfService />
            </React.Suspense>
          } />
          <Route path="/privacy-policy" element={
            <React.Suspense fallback={<BasicBoxLoader contnHeight="screen" />}>
              <PrivacyPolicy />
            </React.Suspense>
          } />
        </Routes>
      </SnackbarContext.Provider>
    </BrowserRouter>
  );
} 
export default App;