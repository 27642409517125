import React, { createRef, useContext, useEffect } from "react";
import tw from "twin.macro";
import Footer from '../footer.js';
import Steps from "./steps.js";
import GenericHeader1 from "../GenericHeader1.js";
import { Suspense } from "react";
import { env_vars } from "../../env.js";
import DocumentMeta from 'react-document-meta';
import { SnackbarContext } from "../snackbar/SnackBarComp.js";
import { useNavigate } from "react-router";
import Pricing from './Pricing';
import DemoVideo from "./DemoVideo.js";
import FAQ from './Faqs.js';
import GetStarted from './CtaGetStarted.js';
import HeroTest from './HeroTest.js'
import Features from './Features.js'
import Carousel from "./Carousel.js";
import Business from "./Business.js"
import Content5 from "./content5.js"
import img1 from '../images/56000_0_test.jpg'
import img2 from '../images/6ecffa0f-2313-46d5-83de-5218456e29b1_3.png'
import img3 from '../images/image (2).webp'
import img4 from '../images/image (4).webp'
import img5 from '../images/image (11).webp'
import img6 from '../images/image (26).webp'
import img7 from '../images/image (29).webp'
import img8 from '../images/image (33).png'
import img9 from '../images/image (34).png'
import img10 from '../images/image (38).png'
import img11 from '../images/image (44).png'
import img12 from '../images/image (54).png'
import img13 from '../images/image (60).png'

function LandingPage() {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-indigo-600`;
  const HighlightedText = tw.span`text-indigo-600`;
  const StyledContentDiv = tw.div` min-h-screen text-gray-700 pt-0 overflow-hidden`;
  const DemoVideoRef = createRef();
  const featuresRef = createRef();
  const pricingRef = createRef();
  const { setErrorPopup } = useContext(SnackbarContext);
  const history =  useNavigate()

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [featuresRef, pricingRef, DemoVideoRef]);

  const meta = {
    title: "Calculense | Best AI Fashion Catalogues",
    description: "",
    canonical: 'https://www.calculense.com/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'AI, Fashion, Catalogues, Fashion catalogs, Fashion Catalogues, Virtaul Try-ons'
      }
    }
  };

  const scrollToVideo = () => {
    DemoVideoRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToFeatures = () => {
    featuresRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const features = [
    {
      title: "AI-Powered catalogueue Creation",
      description: "Leverage cutting-edge AI technology to automatically enhance your cloth images and generate professional-grade catalogues without the need for models or physical garments.",
      svg:  <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
            </svg>
    },
    {
      title: "Cost-Effective Solutions",
      description: "Save on costs associated with photoshoots, modeling fees, and physical samples. Calculense provides a budget-friendly alternative without compromising on quality.",
      svg:  <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM9 7.5A.75.75 0 0 0 9 9h1.5c.98 0 1.813.626 2.122 1.5H9A.75.75 0 0 0 9 12h3.622a2.251 2.251 0 0 1-2.122 1.5H9a.75.75 0 0 0-.53 1.28l3 3a.75.75 0 1 0 1.06-1.06L10.8 14.988A3.752 3.752 0 0 0 14.175 12H15a.75.75 0 0 0 0-1.5h-.825A3.733 3.733 0 0 0 13.5 9H15a.75.75 0 0 0 0-1.5H9Z" clip-rule="evenodd" />
            </svg>
    
    },
    {
      title: "Fast Turnaround Time",
      description: "Create and download polished catalogues in minutes, drastically reducing the time and effort compared to traditional methods. Perfect for fast-paced fashion cycles.",
      svg:  <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z" clip-rule="evenodd" />
            </svg>
    
    },
    {
      title: "Scalable Platform",
      description: "Whether you’re a small boutique or a large enterprise, Calculense scales with your needs. Create unlimited catalogues and manage large volumes with ease.",
      svg:  <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-indigo-600 sm:w-10 sm:h-10" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M2.25 2.25a.75.75 0 0 0 0 1.5H3v10.5a3 3 0 0 0 3 3h1.21l-1.172 3.513a.75.75 0 0 0 1.424.474l.329-.987h8.418l.33.987a.75.75 0 0 0 1.422-.474l-1.17-3.513H18a3 3 0 0 0 3-3V3.75h.75a.75.75 0 0 0 0-1.5H2.25Zm6.54 15h6.42l.5 1.5H8.29l.5-1.5Zm8.085-8.995a.75.75 0 1 0-.75-1.299 12.81 12.81 0 0 0-3.558 3.05L11.03 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l2.47-2.47 1.617 1.618a.75.75 0 0 0 1.146-.102 11.312 11.312 0 0 1 3.612-3.321Z" clip-rule="evenodd" />
            </svg>    
    }
  ];

  const slides = [
    { src: img1, alt: 'sample_image' },
    { src: img2, alt: 'sample_image' },
    { src: img3, alt: 'sample_image' },
    { src: img4, alt: 'sample_image' },
    { src: img5, alt: 'sample_image' },
    { src: img6, alt: 'sample_image' },
    { src: img7, alt: 'sample_image' },
    { src: img8, alt: 'sample_image' },
    { src: img9, alt: 'sample_image' },
    { src: img10, alt: 'sample_image' },
    { src: img11, alt: 'sample_image' },
    { src: img12, alt: 'sample_image' },
    { src: img13, alt: 'sample_image' }
  ];
  
  const faqs = [
    {
      question: "What is Calculense and how does it work ?",
      answer:
        "Calculense is an AI-powered platform that allows you to create professional fashion catalogues without the need for models or physical garments. Simply upload your cloth images, select a template, and our AI algorithms transform them into polished catalogues."
    },
    {
      question: "Why should I use Calculense instead of traditional photoshoots with models?",
      answer:
        "Calculense offers significant advantages over traditional methods. It saves time and costs associated with photoshoots, eliminates the logistical challenges of working with models and physical garments, and ensures consistent quality across all catalogue images."
    },
    {
      question: "How do I create catalogues using Calculense?",
      answer:
        "To create catalogues with Calculense, simply upload your cloth images, select a template or customize the layout to fit your brand's style, and let our AI-powered platform handle the rest. Our algorithms enhance your images to create professional-grade catalogues that you can download or share effortlessly."
    },
    {
      question: "Is there a free trial available?",
      answer:
        "Yes, we offer a free trial of Calculense. During the trial period, you can explore all features of our platform and create a limited number of catalogues at no cost. Sign up for our free trial today to experience the efficiency and quality of AI-driven catalogue creation without any commitment."
    },
    {
      question: "What types of cloth images work best with Calculense?",
      answer:
        "Calculense is designed to work with a wide range of cloth images like T-shirts, Jeans, Pants, Top, Dresses etc. High-resolution images with clear details and textures produce the best results."
    },
    {
      question: "Is there a limit to the number of catalogues I can create with Calculense?",
      answer:
        "No, there are no limits. Whether you’re a small boutique or a large fashion brand, Calculense scales with your needs. You can create and manage as many catalogues as you need to showcase your collections effectively."
    },
    {
      question: "What are the pricing options for Calculense?",
      answer:
        "Calculense offers flexible pricing plans tailored to different business needs. We provide a Basic Plan for smaller enterprises, a Pro Plan for growing businesses with higher volume requirements, and an Enterprise Plan for large-scale operations. Visit our website for detailed pricing information and to choose the plan that best suits your needs."
    },
    {
      question: "How secure is my data on Calculense ?",
      answer:
        "We take data security seriously. Calculense employs industry-standard security measures to protect your data. Your images and information are encrypted and stored securely to ensure confidentiality and privacy."
    }
  ];

  return (
    <>
      <DocumentMeta {...meta}>
      <GenericHeader1 scrollToFeatures={scrollToFeatures} scrollToPricing={scrollToPricing}  />
      <StyledContentDiv>
          <HeroTest scrollToVideo={scrollToVideo}/>
          <Steps/>
          <div id="video-section" ref={DemoVideoRef}>
            <DemoVideo videoUrl="https://www.youtube.com/watch?v=WO2b03Zdu4Q"/>
          </div>
          {/* <Content/> */}
          <div id="features-section" ref={featuresRef}>
            <Features
              title="Unleash the Future of Fashion catalogues"
              description="Transform your fashion catalogue creation with AI-powered precision. Enjoy unparalleled customization, rapid turnaround times, and cost-effective solutions that scale with your business."
              buttonText="Start Free Trail"
              buttonLink="/signup"
              features={features}
            />
          </div>
          <Carousel slides={slides}/>
          <Business/>
          <div id="pricing-plans" ref={pricingRef}>
            <Pricing/>
          </div>
          <Content5/>
          <FAQ
            subheading={<Subheading>FAQS</Subheading>}
            heading={
              <>
                  Frequently Asked <HighlightedText>Questions</HighlightedText>
              </>
            }
            faqs={faqs}
          />
          <GetStarted />
        <Footer />
      </StyledContentDiv>
      </DocumentMeta>
    </>
  );
}

export default LandingPage;