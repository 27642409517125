import { useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { signout } from "./useAuth";
import { useNavigate } from "react-router-dom";
import { LocalStorageName } from "../App";

function useTokenExpire() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = async () => {
      console.log("Checking token expiration...");
      const storedData = JSON.parse(localStorage.getItem(LocalStorageName));
      // console.log("Stored data:", storedData);
      if (storedData && storedData.token) {
        const token = storedData.token;
        const decodedToken = jwtDecode(token);
        // console.log("Decoded token:", decodedToken);
        // console.log("Token expiry time:", decodedToken.exp);
        // console.log("Current time:", Date.now() / 1000);
        if (decodedToken.exp < Date.now() / 1000) {
          console.log("Token expired, signing out...");
          await signout(() => {
            navigate('/login');
          }, () => {
            window.location.reload();
          });
        } else {
          console.log("Token is still valid.");
        }
      } else {
        console.log("User not logged in or token not found.");
      }
    };

    checkTokenExpiration();
  }, [navigate]);
}

export default useTokenExpire;
