import React from "react";

export default () => {
    return (
        <div className="bg-gray-100 p-8">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-green-700 uppercase rounded-full bg-green-200">
                        Customer Support
                    </p>
                </div>
                <h2 className="mb-6 font-semibold text-3xl md:text-5xl leading-snug max-w-3xl text-gray-700  md:mx-auto">
                    We totally understand our customer's need
                    {/* We completely comprehend our customer's requirements. */}
                </h2>
                <p className="text-base text-md text-gray-600 md:text-lg">
                    A completely adaptable approach to your  experience. 
                    Any problems? We've got you covered at every step of the way.
                </p>
            </div>
            <div className="text-center flex flex-wrap justify-center items-center gap-x-4 lg:gap-x-8 xl:gap-x-20 gap-y-8">
                <div class="relative flex flex-col items-center justify-around p-4 mr-4 w-64 h-40 rounded-2xl">
                    <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-300 rounded-xl -rotate-2 -z-1"></div>
                    <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-400 rounded-xl rotate-2 "></div>
                    <div class="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl"></div>
                    <h3 class="z-10 p-2 text-xl md:text-2xl font-semibold text-indigo-600">Free Trail</h3>
                    <div class="z-10 p-2 text-sm md:text-md text-center text-gray-600 font-medium tracking-wide">
                        Generate your first catalogue with first 10 images free.
                        {/* Within first 14 days, if you are not satisfied. */}
                    </div>
                </div>
                <div class="relative flex flex-col items-center justify-around p-4 mr-4 w-64 h-40 rounded-2xl">
                    <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-300 rounded-xl -rotate-2 -z-1"></div>
                    <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-400 rounded-xl rotate-2 "></div>
                    <div class="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl"></div>
                    <h3 class="z-10 p-2 text-xl md:text-2xl font-semibold text-indigo-600">Customer Support</h3>
                    <div class="z-10 p-2 text-sm md:text-md text-center text-gray-600 font-medium tracking-wide">
                        We provide excellent customer service, got you covered at every step !
                    </div>
                </div>
                <div class="relative flex flex-col items-center justify-around p-4 mr-4 w-64 h-40 rounded-2xl">
                    <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-300 rounded-xl -rotate-2 -z-1"></div>
                    <div class="absolute z-0 w-full h-full text-white transform scale-x-105 scale-y-95 bg-indigo-400 rounded-xl rotate-2 "></div>
                    <div class="absolute z-0 w-full h-full transform scale-x-105 scale-y-95 bg-white rounded-xl"></div>
                    <h3 class="z-10 p-2 text-xl md:text-2xl font-semibold text-indigo-600">Money Back</h3>
                    <div class="z-10 p-2 text-sm md:text-md text-center text-gray-600 font-medium tracking-wide">
                        If you are not satisfied, can cancel anytime and you will be refunded.
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};